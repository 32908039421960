import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "bp-login-view__wrapper" }
const _hoisted_2 = {
  class: "bp-login-view__main",
  ref: "mainEl"
}
const _hoisted_3 = { class: "bp-login-view__card-header-wrapper" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "bp-login-view__card-footer-wrapper" }
const _hoisted_6 = { class: "bp-login-view__card-header-wrapper" }
const _hoisted_7 = ["src"]
const _hoisted_8 = { class: "bp-login-view__card-content-wrapper" }
const _hoisted_9 = {
  key: 1,
  class: "bp-login-view__card-footer-wrapper"
}
const _hoisted_10 = { class: "bp-login-view__card-header-wrapper" }
const _hoisted_11 = ["src"]
const _hoisted_12 = { class: "bp-login-view__card-header-wrapper" }
const _hoisted_13 = ["src"]
const _hoisted_14 = { class: "bp-login-view__card-header-wrapper" }
const _hoisted_15 = ["src"]
const _hoisted_16 = { class: "flex-column gap-md" }
const _hoisted_17 = { class: "flex gap-md" }
const _hoisted_18 = {
  key: 0,
  style: {"opacity":"0.5"}
}
const _hoisted_19 = { class: "bp-login-view__card-header-wrapper" }
const _hoisted_20 = ["src"]
const _hoisted_21 = { class: "flex-column gap-md" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_translate = _resolveComponent("translate")!
  const _component_bp_icon = _resolveComponent("bp-icon")!
  const _component_bp_input = _resolveComponent("bp-input")!
  const _component_bp_header = _resolveComponent("bp-header")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_bp_button = _resolveComponent("bp-button")!
  const _component_bp_card = _resolveComponent("bp-card")!
  const _component_bp_video_preview = _resolveComponent("bp-video-preview")!
  const _component_bp_form_button = _resolveComponent("bp-form-button")!
  const _component_bp_password_validator = _resolveComponent("bp-password-validator")!
  const _component_bp_alert = _resolveComponent("bp-alert")!
  const _component_bp_select = _resolveComponent("bp-select")!
  const _component_bp_loading = _resolveComponent("bp-loading")!
  const _component_bp_captcha = _resolveComponent("bp-captcha")!
  const _component_bp_label = _resolveComponent("bp-label")!
  const _component_bp_window = _resolveComponent("bp-window")!

  return (_openBlock(), _createElementBlock("div", {
    class: "bp-login-view",
    style: _normalizeStyle(_ctx.style)
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_bp_header, {
        id: "bp-header",
        "is-active": _ctx.isActive,
        "main-nav": _ctx.loginNav,
        "side-nav": _ctx.customizeAppearance,
        ref: "headerEl",
        "side-nav-icon": "gear"
      }, {
        "side-nav-header": _withCtx(() => [
          _createVNode(_component_translate, { tag: "strong" }, {
            default: _withCtx(() => _cache[51] || (_cache[51] = [
              _createTextVNode("Cordaware customer portal")
            ])),
            _: 1
          })
        ]),
        "side-nav-option-dark_mode": _withCtx(({ option }) => [
          _createVNode(_component_bp_icon, {
            icon: option.icon,
            style: {"color":"var(--theme-text-disabled)"}
          }, null, 8, ["icon"]),
          _createElementVNode("span", null, _toDisplayString(option.name), 1),
          _createVNode(_component_bp_input, {
            type: "toggle",
            "model-value": _ctx.darkMode,
            style: {"margin-left":"auto","pointer-events":"none"}
          }, null, 8, ["model-value"])
        ]),
        "side-nav-option-compact_mode": _withCtx(({ option }) => [
          _createVNode(_component_bp_icon, {
            icon: option.icon,
            style: {"color":"var(--theme-text-disabled)"}
          }, null, 8, ["icon"]),
          _createElementVNode("span", null, _toDisplayString(option.name), 1),
          _createVNode(_component_bp_input, {
            type: "toggle",
            "model-value": _ctx.compactMode,
            style: {"margin-left":"auto","pointer-events":"none"}
          }, null, 8, ["model-value"])
        ]),
        _: 1
      }, 8, ["is-active", "main-nav", "side-nav"]),
      _createElementVNode("main", _hoisted_2, [
        (!_ctx.action)
          ? (_openBlock(), _createBlock(_component_bp_card, {
              key: 0,
              "fill-height": "",
              "fill-width": !_ctx.isDesktop,
              "large-footer": _ctx.isDesktop,
              "large-header": _ctx.isDesktop,
              "max-height": _ctx.maxHeight,
              class: "bp-login-view__card",
              opaque: ""
            }, {
              header: _withCtx(() => [
                _createElementVNode("h2", _hoisted_3, [
                  _createElementVNode("img", {
                    class: "bp-login-view__cordaware-logo",
                    src: `/static/logo/cordaware_${_ctx.darkMode ? 'dark' : 'light'}.png`
                  }, null, 8, _hoisted_4),
                  _createVNode(_component_translate, { class: "bp-login-view__header-prefix" }, {
                    default: _withCtx(() => _cache[52] || (_cache[52] = [
                      _createTextVNode("Welcome to the")
                    ])),
                    _: 1
                  }),
                  _createVNode(_component_translate, { class: "bp-login-view__header-suffix" }, {
                    default: _withCtx(() => _cache[53] || (_cache[53] = [
                      _createTextVNode("Customer portal")
                    ])),
                    _: 1
                  })
                ])
              ]),
              footer: _withCtx(() => [
                _createElementVNode("div", _hoisted_5, [
                  _createVNode(_component_translate, null, {
                    default: _withCtx(() => _cache[57] || (_cache[57] = [
                      _createTextVNode("You don't have an account, yet? Just click here to register.")
                    ])),
                    _: 1
                  }),
                  _createVNode(_component_bp_button, {
                    large: "",
                    color: "light-blue",
                    icon: ['far', 'pen-to-square'],
                    to: { name: 'login', query: { action: 'register' } }
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_translate, null, {
                        default: _withCtx(() => _cache[58] || (_cache[58] = [
                          _createTextVNode("Register")
                        ])),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ])
              ]),
              default: _withCtx(() => [
                _createElementVNode("form", {
                  class: "bp-login-view__card-content-wrapper",
                  onSubmit: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.login && _ctx.login(...args)), ["prevent"]))
                }, [
                  _createVNode(_component_translate, { tag: "h3" }, {
                    default: _withCtx(() => _cache[54] || (_cache[54] = [
                      _createTextVNode("User login")
                    ])),
                    _: 1
                  }),
                  _createVNode(_component_bp_input, {
                    label: _ctx.$gettext('Email address'),
                    placeholder: _ctx.$gettext('e.g.') + ' ' + _ctx.$gettext('forename.lastname@company.com'),
                    type: _ctx.loginInputType,
                    maxlength: -1,
                    "label-position": "top",
                    autocomplete: "username",
                    modelValue: _ctx.loginData.email,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.loginData.email) = $event)),
                    ref: "loginInputEl"
                  }, null, 8, ["label", "placeholder", "type", "modelValue"]),
                  _createVNode(_component_bp_input, {
                    label: _ctx.$gettext('Password'),
                    placeholder: _ctx.$gettext('e.g.') + ' C0rd4w4r3!',
                    "label-position": "top",
                    type: "password",
                    autocomplete: "current-password",
                    modelValue: _ctx.loginData.password,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.loginData.password) = $event))
                  }, {
                    text: _withCtx(() => [
                      _createVNode(_component_router_link, { to: { name: 'login', query: { action: 'forgot-password' } } }, {
                        default: _withCtx(() => [
                          _createVNode(_component_translate, null, {
                            default: _withCtx(() => _cache[55] || (_cache[55] = [
                              _createTextVNode("Forgot your password?")
                            ])),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["label", "placeholder", "modelValue"]),
                  _createVNode(_component_bp_button, {
                    large: "",
                    color: "green",
                    icon: "arrow-right-to-bracket",
                    type: "submit"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_translate, null, {
                        default: _withCtx(() => _cache[56] || (_cache[56] = [
                          _createTextVNode("Login")
                        ])),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ], 32)
              ]),
              _: 1
            }, 8, ["fill-width", "large-footer", "large-header", "max-height"]))
          : (_ctx.action === 'webinar')
            ? (_openBlock(), _createBlock(_component_bp_card, {
                key: 1,
                "fill-height": "",
                "fill-width": !_ctx.isDesktop,
                "large-footer": _ctx.isDesktop,
                "large-header": _ctx.isDesktop,
                "max-height": _ctx.maxHeight,
                class: "bp-login-view__card",
                opaque: ""
              }, {
                header: _withCtx(() => [
                  _createElementVNode("h1", _hoisted_6, [
                    _createElementVNode("img", {
                      class: "bp-login-view__cordaware-logo",
                      src: `/static/logo/cordaware_${_ctx.darkMode ? 'dark' : 'light'}.png`
                    }, null, 8, _hoisted_7),
                    _createVNode(_component_translate, { class: "bp-login-view__header-prefix" }, {
                      default: _withCtx(() => _cache[59] || (_cache[59] = [
                        _createTextVNode("Welcome to the")
                      ])),
                      _: 1
                    }),
                    _createVNode(_component_translate, { class: "bp-login-view__header-suffix" }, {
                      default: _withCtx(() => _cache[60] || (_cache[60] = [
                        _createTextVNode("Customer portal")
                      ])),
                      _: 1
                    })
                  ])
                ]),
                footer: _withCtx(() => [
                  (_ctx.webinarData.preview && _ctx.webinarData.preview.webinar.active)
                    ? (_openBlock(), _createElementBlock("form", {
                        key: 0,
                        class: "bp-login-view__card-footer-wrapper",
                        onSubmit: _cache[7] || (_cache[7] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.participate && _ctx.participate(...args)), ["prevent"]))
                      }, [
                        _createVNode(_component_bp_input, {
                          label: _ctx.$gettext('Webinar token'),
                          placeholder: _ctx.$gettext('e.g.') + ' ' + _ctx.randomCustomerNumber,
                          onChangeValid: _cache[3] || (_cache[3] = ($event: any) => (_ctx.setWebinarError('webinar.token', _ctx.$gettext('Webinar token'), $event))),
                          "label-position": "top",
                          desiredlength: 16,
                          required: "",
                          type: "text",
                          modelValue: _ctx.webinarData.token,
                          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.webinarData.token) = $event))
                        }, null, 8, ["label", "placeholder", "modelValue"]),
                        _createVNode(_component_bp_input, {
                          label: _ctx.$gettext('Desired username'),
                          placeholder: _ctx.$gettext('e.g.') + ' ' + _ctx.$gettext('ForenameLastname'),
                          onChangeValid: _cache[5] || (_cache[5] = ($event: any) => (_ctx.setWebinarError('username', _ctx.$gettext('Desired username'), $event))),
                          "onUpdate:modelValue": [
                            _ctx.checkUsername,
                            _cache[6] || (_cache[6] = ($event: any) => ((_ctx.webinarData.username) = $event))
                          ],
                          pattern: "[\\wÄÖÜäöüß]",
                          error: _ctx.usernameError,
                          "label-position": "top",
                          type: "text",
                          modelValue: _ctx.webinarData.username
                        }, {
                          text: _withCtx(() => [
                            _createVNode(_component_translate, null, {
                              default: _withCtx(() => _cache[62] || (_cache[62] = [
                                _createTextVNode("Your username may only consist of alphanumeric characters. If you don't enter a username, you will be assigned a default one.")
                              ])),
                              _: 1
                            })
                          ]),
                          _: 1
                        }, 8, ["label", "placeholder", "onUpdate:modelValue", "error", "modelValue"]),
                        _createVNode(_component_bp_form_button, {
                          large: "",
                          color: "green",
                          icon: "arrow-right-to-bracket",
                          errors: _ctx.webinarErrors,
                          action: _ctx.participate
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_translate, null, {
                              default: _withCtx(() => _cache[63] || (_cache[63] = [
                                _createTextVNode("Participate")
                              ])),
                              _: 1
                            })
                          ]),
                          _: 1
                        }, 8, ["errors", "action"])
                      ], 32))
                    : (_ctx.webinarData.preview)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                          _createVNode(_component_translate, null, {
                            default: _withCtx(() => _cache[64] || (_cache[64] = [
                              _createTextVNode("You want to participate in this webinar? Just click here to sign up.")
                            ])),
                            _: 1
                          }),
                          _createVNode(_component_bp_button, {
                            large: "",
                            color: "light-blue",
                            icon: ['far', 'pen-to-square'],
                            href: _ctx.webinarData.href
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_translate, null, {
                                default: _withCtx(() => _cache[65] || (_cache[65] = [
                                  _createTextVNode("Sign up")
                                ])),
                                _: 1
                              })
                            ]),
                            _: 1
                          }, 8, ["href"])
                        ]))
                      : (_openBlock(), _createBlock(_component_bp_button, {
                          key: 2,
                          class: "flex-1",
                          icon: "arrow-left",
                          large: "",
                          to: { name: 'login' }
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_translate, null, {
                              default: _withCtx(() => _cache[66] || (_cache[66] = [
                                _createTextVNode("Back to login")
                              ])),
                              _: 1
                            })
                          ]),
                          _: 1
                        }))
                ]),
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_8, [
                    _createVNode(_component_translate, { tag: "h3" }, {
                      default: _withCtx(() => _cache[61] || (_cache[61] = [
                        _createTextVNode("Webinar login")
                      ])),
                      _: 1
                    }),
                    (_openBlock(), _createBlock(_component_bp_video_preview, {
                      "model-value": _ctx.webinarData.preview,
                      key: _ctx.webinarData.preview?._id + '_' + _ctx.webinarData.previewActive,
                      "hide-button": "",
                      "hide-icon": ""
                    }, null, 8, ["model-value"]))
                  ])
                ]),
                _: 1
              }, 8, ["fill-width", "large-footer", "large-header", "max-height"]))
            : (_ctx.action === 'forgot-password')
              ? (_openBlock(), _createBlock(_component_bp_card, {
                  key: 2,
                  "fill-height": "",
                  "fill-width": !_ctx.isDesktop,
                  "large-footer": _ctx.isDesktop,
                  "large-header": _ctx.isDesktop,
                  "max-height": _ctx.maxHeight,
                  class: "bp-login-view__card",
                  opaque: ""
                }, {
                  header: _withCtx(() => [
                    _createElementVNode("h1", _hoisted_10, [
                      _createElementVNode("img", {
                        class: "bp-login-view__cordaware-logo",
                        src: `/static/logo/cordaware_${_ctx.darkMode ? 'dark' : 'light'}.png`
                      }, null, 8, _hoisted_11),
                      _createVNode(_component_translate, { class: "bp-login-view__header-prefix" }, {
                        default: _withCtx(() => _cache[67] || (_cache[67] = [
                          _createTextVNode("Welcome to the")
                        ])),
                        _: 1
                      }),
                      _createVNode(_component_translate, { class: "bp-login-view__header-suffix" }, {
                        default: _withCtx(() => _cache[68] || (_cache[68] = [
                          _createTextVNode("Customer portal")
                        ])),
                        _: 1
                      })
                    ])
                  ]),
                  footer: _withCtx(() => [
                    _createVNode(_component_bp_button, {
                      class: "flex-1",
                      icon: "arrow-left",
                      large: "",
                      to: { name: 'login' }
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_translate, null, {
                          default: _withCtx(() => _cache[73] || (_cache[73] = [
                            _createTextVNode("Back to login")
                          ])),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  default: _withCtx(() => [
                    _createElementVNode("form", {
                      class: "bp-login-view__card-content-wrapper",
                      onSubmit: _cache[12] || (_cache[12] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.forgotPassword && _ctx.forgotPassword(...args)), ["prevent"]))
                    }, [
                      _createVNode(_component_translate, { tag: "h3" }, {
                        default: _withCtx(() => _cache[69] || (_cache[69] = [
                          _createTextVNode("Forgot your password?")
                        ])),
                        _: 1
                      }),
                      _createVNode(_component_bp_input, {
                        label: _ctx.$gettext('Customer number'),
                        placeholder: _ctx.$gettext('e.g.') + ' ' + _ctx.randomCustomerNumber,
                        onChangeValid: _cache[8] || (_cache[8] = ($event: any) => (_ctx.setForgotPasswordError('customer_number', _ctx.$gettext('Customer number'), $event))),
                        "label-position": "top",
                        desiredlength: 8,
                        required: "",
                        type: "text",
                        modelValue: _ctx.forgotPasswordData.customerNumber,
                        "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.forgotPasswordData.customerNumber) = $event))
                      }, {
                        text: _withCtx(() => [
                          _createVNode(_component_translate, { tag: "p" }, {
                            default: _withCtx(() => _cache[70] || (_cache[70] = [
                              _createTextVNode("You can find your customer number in your order confirmation.")
                            ])),
                            _: 1
                          }),
                          _createVNode(_component_translate, { tag: "p" }, {
                            default: _withCtx(() => _cache[71] || (_cache[71] = [
                              _createTextVNode("If you are already registered you can also find it in the home view after you successfully logged into your account.")
                            ])),
                            _: 1
                          })
                        ]),
                        _: 1
                      }, 8, ["label", "placeholder", "modelValue"]),
                      _createVNode(_component_bp_input, {
                        label: _ctx.$gettext('Email address'),
                        placeholder: _ctx.$gettext('e.g.') + ' ' + _ctx.$gettext('forename.lastname@company.com'),
                        onChangeValid: _cache[10] || (_cache[10] = ($event: any) => (_ctx.setForgotPasswordError('email', _ctx.$gettext('Email address'), $event))),
                        "label-position": "top",
                        maxlength: -1,
                        required: "",
                        type: "email",
                        modelValue: _ctx.forgotPasswordData.email,
                        "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.forgotPasswordData.email) = $event))
                      }, null, 8, ["label", "placeholder", "modelValue"]),
                      _createVNode(_component_bp_form_button, {
                        large: "",
                        color: "green",
                        icon: ['far', 'envelope'],
                        errors: _ctx.forgotPasswordErrors,
                        action: _ctx.forgotPassword
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_translate, null, {
                            default: _withCtx(() => _cache[72] || (_cache[72] = [
                              _createTextVNode("Send password reset instructions")
                            ])),
                            _: 1
                          })
                        ]),
                        _: 1
                      }, 8, ["errors", "action"])
                    ], 32)
                  ]),
                  _: 1
                }, 8, ["fill-width", "large-footer", "large-header", "max-height"]))
              : (_ctx.action === 'reset-password')
                ? (_openBlock(), _createBlock(_component_bp_card, {
                    key: 3,
                    "fill-height": "",
                    "fill-width": !_ctx.isDesktop,
                    "large-footer": _ctx.isDesktop,
                    "large-header": _ctx.isDesktop,
                    "max-height": _ctx.maxHeight,
                    class: "bp-login-view__card",
                    opaque: ""
                  }, {
                    header: _withCtx(() => [
                      _createElementVNode("h1", _hoisted_12, [
                        _createElementVNode("img", {
                          class: "bp-login-view__cordaware-logo",
                          src: `/static/logo/cordaware_${_ctx.darkMode ? 'dark' : 'light'}.png`
                        }, null, 8, _hoisted_13),
                        _createVNode(_component_translate, { class: "bp-login-view__header-prefix" }, {
                          default: _withCtx(() => _cache[74] || (_cache[74] = [
                            _createTextVNode("Welcome to the")
                          ])),
                          _: 1
                        }),
                        _createVNode(_component_translate, { class: "bp-login-view__header-suffix" }, {
                          default: _withCtx(() => _cache[75] || (_cache[75] = [
                            _createTextVNode("Customer portal")
                          ])),
                          _: 1
                        })
                      ])
                    ]),
                    footer: _withCtx(() => [
                      _createVNode(_component_bp_button, {
                        class: "flex-1",
                        icon: "arrow-left",
                        large: "",
                        to: { name: 'login' }
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_translate, null, {
                            default: _withCtx(() => _cache[78] || (_cache[78] = [
                              _createTextVNode("Back to login")
                            ])),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    default: _withCtx(() => [
                      _createElementVNode("form", {
                        class: "bp-login-view__card-content-wrapper",
                        onSubmit: _cache[22] || (_cache[22] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.resetPassword && _ctx.resetPassword(...args)), ["prevent"]))
                      }, [
                        _createVNode(_component_translate, { tag: "h3" }, {
                          default: _withCtx(() => _cache[76] || (_cache[76] = [
                            _createTextVNode("Reset password")
                          ])),
                          _: 1
                        }),
                        _createVNode(_component_bp_input, {
                          label: _ctx.$gettext('Email address'),
                          placeholder: _ctx.$gettext('e.g.') + ' ' + _ctx.$gettext('forename.lastname@company.com'),
                          onChangeValid: _cache[13] || (_cache[13] = ($event: any) => (_ctx.setResetPasswordError('email', _ctx.$gettext('Email address'), $event))),
                          "label-position": "top",
                          maxlength: -1,
                          required: "",
                          type: "email",
                          modelValue: _ctx.resetPasswordData.email,
                          "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.resetPasswordData.email) = $event))
                        }, null, 8, ["label", "placeholder", "modelValue"]),
                        _createVNode(_component_bp_input, {
                          error: (_ctx.resetPasswordData.password.dirty && _ctx.resetPasswordData.password.error) ? _ctx.resetPasswordData.password.error : undefined,
                          label: _ctx.$gettext('Password'),
                          placeholder: _ctx.$gettext('e.g.') + ' C0rd4w4r3!',
                          valid: !_ctx.resetPasswordData.password.error,
                          onChangeDirty: _cache[16] || (_cache[16] = ($event: any) => (_ctx.resetPasswordData.password.dirty = $event)),
                          onChangeValid: _cache[17] || (_cache[17] = ($event: any) => (_ctx.setResetPasswordError('password', _ctx.$gettext('Password'), $event))),
                          autocomplete: "new-password",
                          "label-position": "top",
                          required: "",
                          type: "password",
                          modelValue: _ctx.resetPasswordData.password.value,
                          "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.resetPasswordData.password.value) = $event))
                        }, {
                          text: _withCtx(() => [
                            _createVNode(_component_bp_password_validator, {
                              password: _ctx.resetPasswordData.password.value,
                              onChangeValid: _cache[15] || (_cache[15] = ($event: any) => (_ctx.registerData.password.error = $event))
                            }, null, 8, ["password"])
                          ]),
                          _: 1
                        }, 8, ["error", "label", "placeholder", "valid", "modelValue"]),
                        _createVNode(_component_bp_input, {
                          error: (_ctx.resetPasswordData.confirmation.dirty && _ctx.resetPasswordData.password.value !== _ctx.resetPasswordData.confirmation.value) ? _ctx.$gettext('The passwords do not match') : undefined,
                          disabled: !!_ctx.resetPasswordData.password.error,
                          label: _ctx.$gettext('Confirm password'),
                          placeholder: _ctx.$gettext('e.g.') + ' C0rd4w4r3!',
                          valid: _ctx.resetPasswordData.password.value === _ctx.resetPasswordData.confirmation.value,
                          onChangeDirty: _cache[19] || (_cache[19] = ($event: any) => (_ctx.resetPasswordData.confirmation.dirty = $event)),
                          onChangeValid: _cache[20] || (_cache[20] = ($event: any) => (_ctx.setResetPasswordError('confirm_password', _ctx.$gettext('Confirm password'), $event))),
                          autocomplete: "new-password",
                          "label-position": "top",
                          required: "",
                          type: "password",
                          modelValue: _ctx.resetPasswordData.confirmation.value,
                          "onUpdate:modelValue": _cache[21] || (_cache[21] = ($event: any) => ((_ctx.resetPasswordData.confirmation.value) = $event))
                        }, null, 8, ["error", "disabled", "label", "placeholder", "valid", "modelValue"]),
                        _createVNode(_component_bp_form_button, {
                          large: "",
                          color: "green",
                          icon: ['far', 'key'],
                          errors: _ctx.resetPasswordErrors,
                          action: _ctx.resetPassword
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_translate, null, {
                              default: _withCtx(() => _cache[77] || (_cache[77] = [
                                _createTextVNode("Reset password")
                              ])),
                              _: 1
                            })
                          ]),
                          _: 1
                        }, 8, ["errors", "action"])
                      ], 32)
                    ]),
                    _: 1
                  }, 8, ["fill-width", "large-footer", "large-header", "max-height"]))
                : (_ctx.action === 'register')
                  ? (_openBlock(), _createBlock(_component_bp_card, {
                      key: 4,
                      "fill-height": "",
                      "fill-width": !_ctx.isDesktop,
                      "large-footer": _ctx.isDesktop,
                      "large-header": _ctx.isDesktop,
                      "max-height": _ctx.maxHeight,
                      class: "bp-login-view__card",
                      opaque: ""
                    }, {
                      header: _withCtx(() => [
                        _createElementVNode("h1", _hoisted_14, [
                          _createElementVNode("img", {
                            class: "bp-login-view__cordaware-logo",
                            src: `/static/logo/cordaware_${_ctx.darkMode ? 'dark' : 'light'}.png`
                          }, null, 8, _hoisted_15),
                          _createVNode(_component_translate, { class: "bp-login-view__header-prefix" }, {
                            default: _withCtx(() => _cache[79] || (_cache[79] = [
                              _createTextVNode("Welcome to the")
                            ])),
                            _: 1
                          }),
                          _createVNode(_component_translate, { class: "bp-login-view__header-suffix" }, {
                            default: _withCtx(() => _cache[80] || (_cache[80] = [
                              _createTextVNode("Customer portal")
                            ])),
                            _: 1
                          })
                        ])
                      ]),
                      footer: _withCtx(() => [
                        _createVNode(_component_bp_button, {
                          class: "flex-1",
                          icon: "arrow-left",
                          large: "",
                          to: { name: 'login' }
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_translate, null, {
                              default: _withCtx(() => _cache[88] || (_cache[88] = [
                                _createTextVNode("Back to login")
                              ])),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      default: _withCtx(() => [
                        _createElementVNode("form", {
                          class: "bp-login-view__card-content-wrapper",
                          onSubmit: _cache[42] || (_cache[42] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.register && _ctx.register(...args)), ["prevent"]))
                        }, [
                          _createVNode(_component_translate, { tag: "h3" }, {
                            default: _withCtx(() => _cache[81] || (_cache[81] = [
                              _createTextVNode("Register")
                            ])),
                            _: 1
                          }),
                          (!_ctx.cookieConsent)
                            ? (_openBlock(), _createBlock(_component_bp_alert, {
                                key: 0,
                                icon: "circle-info"
                              }, {
                                default: _withCtx(() => [
                                  _createElementVNode("div", _hoisted_16, [
                                    _createVNode(_component_translate, null, {
                                      default: _withCtx(() => _cache[82] || (_cache[82] = [
                                        _createTextVNode("In order to register a new account you have to allow third party cookies")
                                      ])),
                                      _: 1
                                    }),
                                    _createVNode(_component_bp_button, {
                                      icon: ['far', 'cookie-bite'],
                                      onClick: _ctx.openCookieBanner
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_translate, null, {
                                          default: _withCtx(() => _cache[83] || (_cache[83] = [
                                            _createTextVNode("Update cookie consent")
                                          ])),
                                          _: 1
                                        })
                                      ]),
                                      _: 1
                                    }, 8, ["onClick"])
                                  ])
                                ]),
                                _: 1
                              }))
                            : _createCommentVNode("", true),
                          _createVNode(_component_bp_input, {
                            disabled: !_ctx.cookieConsent,
                            label: _ctx.$gettext('Customer number'),
                            placeholder: _ctx.$gettext('e.g.') + ' ' + _ctx.randomCustomerNumber,
                            onChangeValid: _cache[23] || (_cache[23] = ($event: any) => (_ctx.setRegisterError('customer_number', _ctx.$gettext('Customer number'), $event))),
                            "label-position": "top",
                            desiredlength: 8,
                            required: "",
                            type: "text",
                            modelValue: _ctx.registerData.customerNumber,
                            "onUpdate:modelValue": _cache[24] || (_cache[24] = ($event: any) => ((_ctx.registerData.customerNumber) = $event))
                          }, {
                            text: _withCtx(() => [
                              _createVNode(_component_translate, { tag: "p" }, {
                                default: _withCtx(() => _cache[84] || (_cache[84] = [
                                  _createTextVNode("You can find your customer number in your order confirmation.")
                                ])),
                                _: 1
                              }),
                              _createVNode(_component_translate, { tag: "p" }, {
                                default: _withCtx(() => _cache[85] || (_cache[85] = [
                                  _createTextVNode("If you are already registered you can also find it in the home view after you successfully logged into your account.")
                                ])),
                                _: 1
                              })
                            ]),
                            _: 1
                          }, 8, ["disabled", "label", "placeholder", "modelValue"]),
                          _createVNode(_component_bp_select, {
                            data: _ctx.salutationStore.salutations,
                            disabled: !_ctx.cookieConsent,
                            label: _ctx.$gettext('Salutation'),
                            onChangeValid: _cache[25] || (_cache[25] = ($event: any) => (_ctx.setRegisterError('salutation', _ctx.$gettext('Salutation'), $event))),
                            "close-on-click": "",
                            "label-position": "top",
                            required: "",
                            "search-property": "name",
                            style: {"width":"unset !important"},
                            modelValue: _ctx.registerData.salutation,
                            "onUpdate:modelValue": _cache[26] || (_cache[26] = ($event: any) => ((_ctx.registerData.salutation) = $event))
                          }, {
                            option: _withCtx(({ option }) => [
                              _createElementVNode("div", _hoisted_17, [
                                (option.icon)
                                  ? (_openBlock(), _createBlock(_component_bp_icon, {
                                      key: 0,
                                      icon: option.icon
                                    }, null, 8, ["icon"]))
                                  : _createCommentVNode("", true),
                                _createElementVNode("span", null, [
                                  _createTextVNode(_toDisplayString(option.name) + " ", 1),
                                  (!option._disabled)
                                    ? (_openBlock(), _createElementBlock("em", _hoisted_18, "(" + _toDisplayString(option.example) + ")", 1))
                                    : _createCommentVNode("", true)
                                ])
                              ])
                            ]),
                            _: 1
                          }, 8, ["data", "disabled", "label", "modelValue"]),
                          _createElementVNode("div", {
                            class: _normalizeClass(['gap-md', {
              'flex': _ctx.isTablet,
              'flex-column': !_ctx.isTablet
            }])
                          }, [
                            _createVNode(_component_bp_input, {
                              disabled: !_ctx.cookieConsent,
                              label: _ctx.$gettext('Forename'),
                              placeholder: _ctx.$gettext('e.g.') + ' ' + _ctx.$gettext('forename'),
                              onChangeValid: _cache[27] || (_cache[27] = ($event: any) => (_ctx.setRegisterError('forename', _ctx.$gettext('Forename'), $event))),
                              class: "flex-1",
                              maxlength: 100,
                              "label-position": "top",
                              required: "",
                              modelValue: _ctx.registerData.forename,
                              "onUpdate:modelValue": _cache[28] || (_cache[28] = ($event: any) => ((_ctx.registerData.forename) = $event))
                            }, null, 8, ["disabled", "label", "placeholder", "modelValue"]),
                            _createVNode(_component_bp_input, {
                              disabled: !_ctx.cookieConsent,
                              label: _ctx.$gettext('Surname'),
                              placeholder: _ctx.$gettext('e.g.') + ' ' + _ctx.$gettext('surname'),
                              onChangeValid: _cache[29] || (_cache[29] = ($event: any) => (_ctx.setRegisterError('surname', _ctx.$gettext('Surname'), $event))),
                              class: "flex-1",
                              maxlength: 100,
                              "label-position": "top",
                              required: "",
                              modelValue: _ctx.registerData.surname,
                              "onUpdate:modelValue": _cache[30] || (_cache[30] = ($event: any) => ((_ctx.registerData.surname) = $event))
                            }, null, 8, ["disabled", "label", "placeholder", "modelValue"])
                          ], 2),
                          _createVNode(_component_bp_input, {
                            disabled: !_ctx.cookieConsent,
                            label: _ctx.$gettext('Email address'),
                            placeholder: _ctx.$gettext('e.g.') + ' ' + _ctx.$gettext('forename.lastname@company.com'),
                            onChangeValid: _cache[31] || (_cache[31] = ($event: any) => (_ctx.setRegisterError('email', _ctx.$gettext('Email address'), $event))),
                            "label-position": "top",
                            required: "",
                            type: "email",
                            modelValue: _ctx.registerData.email,
                            "onUpdate:modelValue": _cache[32] || (_cache[32] = ($event: any) => ((_ctx.registerData.email) = $event))
                          }, null, 8, ["disabled", "label", "placeholder", "modelValue"]),
                          _createVNode(_component_bp_input, {
                            disabled: !_ctx.cookieConsent,
                            error: (_ctx.registerData.password.dirty && _ctx.registerData.password.error) ? _ctx.registerData.password.error : undefined,
                            label: _ctx.$gettext('Password'),
                            placeholder: _ctx.$gettext('e.g.') + ' C0rd4w4r3!',
                            valid: !_ctx.registerData.password.error,
                            onChangeDirty: _cache[34] || (_cache[34] = ($event: any) => (_ctx.registerData.password.dirty = $event)),
                            onChangeValid: _cache[35] || (_cache[35] = ($event: any) => (_ctx.setRegisterError('password', _ctx.$gettext('Password'), $event))),
                            autocomplete: "new-password",
                            "label-position": "top",
                            required: "",
                            type: "password",
                            modelValue: _ctx.registerData.password.value,
                            "onUpdate:modelValue": _cache[36] || (_cache[36] = ($event: any) => ((_ctx.registerData.password.value) = $event))
                          }, {
                            text: _withCtx(() => [
                              _createVNode(_component_bp_password_validator, {
                                password: _ctx.registerData.password.value,
                                onChangeValid: _cache[33] || (_cache[33] = ($event: any) => (_ctx.registerData.password.error = $event))
                              }, null, 8, ["password"])
                            ]),
                            _: 1
                          }, 8, ["disabled", "error", "label", "placeholder", "valid", "modelValue"]),
                          _createVNode(_component_bp_input, {
                            error: (_ctx.registerData.confirmation.dirty && _ctx.registerData.password.value !== _ctx.registerData.confirmation.value) ? _ctx.$gettext('The passwords do not match') : undefined,
                            disabled: !!_ctx.registerData.password.error || !_ctx.cookieConsent,
                            label: _ctx.$gettext('Confirm password'),
                            placeholder: _ctx.$gettext('e.g.') + ' C0rd4w4r3!',
                            valid: _ctx.registerData.password.value === _ctx.registerData.confirmation.value,
                            onChangeDirty: _cache[37] || (_cache[37] = ($event: any) => (_ctx.registerData.confirmation.dirty = $event)),
                            onChangeValid: _cache[38] || (_cache[38] = ($event: any) => (_ctx.setRegisterError('confirm_password', _ctx.$gettext('Confirm password'), $event))),
                            autocomplete: "new-password",
                            "label-position": "top",
                            required: "",
                            type: "password",
                            modelValue: _ctx.registerData.confirmation.value,
                            "onUpdate:modelValue": _cache[39] || (_cache[39] = ($event: any) => ((_ctx.registerData.confirmation.value) = $event))
                          }, null, 8, ["error", "disabled", "label", "placeholder", "valid", "modelValue"]),
                          _createVNode(_component_bp_input, {
                            disabled: !_ctx.cookieConsent,
                            label: _ctx.$gettext('Privacy policy'),
                            onChangeValid: _cache[40] || (_cache[40] = ($event: any) => (_ctx.setRegisterError('privacy_policy', _ctx.$gettext('Privacy policy'), $event))),
                            "label-position": "top",
                            seamless: "",
                            type: "checkbox",
                            modelValue: _ctx.registerData.privacyPolicy,
                            "onUpdate:modelValue": _cache[41] || (_cache[41] = ($event: any) => ((_ctx.registerData.privacyPolicy) = $event)),
                            required: ""
                          }, {
                            text: _withCtx(() => [
                              _createVNode(_component_translate, null, {
                                default: _withCtx(() => _cache[86] || (_cache[86] = [
                                  _createTextVNode("I agree with the "),
                                  _createElementVNode("a", {
                                    href: "https://www.cordaware.com/eng/data-privacy-policy",
                                    target: "_blank"
                                  }, "data privacy policy", -1),
                                  _createTextVNode(" guidelines.")
                                ])),
                                _: 1
                              })
                            ]),
                            _: 1
                          }, 8, ["disabled", "label", "modelValue"]),
                          (_ctx.cookieConsent)
                            ? (_openBlock(), _createBlock(_component_bp_label, {
                                key: 1,
                                label: _ctx.$gettext('Captcha'),
                                "label-position": "top",
                                required: ""
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_bp_loading, {
                                    "model-value": _ctx.captcha.loading,
                                    inline: ""
                                  }, null, 8, ["model-value"]),
                                  (_openBlock(), _createBlock(_component_bp_captcha, {
                                    key: _ctx.darkMode + '_' + _ctx.currentLanguageISOString(),
                                    language: _ctx.currentLanguageISOString(),
                                    sitekey: _ctx.captcha.sitekey,
                                    tabindex: 0,
                                    theme: _ctx.darkMode ? 'dark' : 'light',
                                    onChallengeExpired: _ctx.captchaInvalid,
                                    onError: _ctx.captchaInvalid,
                                    onExpired: _ctx.captchaInvalid,
                                    onRendered: _ctx.captchaRendered,
                                    onVerify: _ctx.captchaVerify,
                                    size: "normal"
                                  }, null, 8, ["language", "sitekey", "theme", "onChallengeExpired", "onError", "onExpired", "onRendered", "onVerify"]))
                                ]),
                                _: 1
                              }, 8, ["label"]))
                            : _createCommentVNode("", true),
                          _createVNode(_component_bp_form_button, {
                            icon: ['far', 'pen-to-square'],
                            large: "",
                            errors: !_ctx.cookieConsent ? new Map([['cookie_consent', { name: _ctx.$gettext('Cookie consent'), message: _ctx.$gettext('Is required.') }]]) : _ctx.registerErrors,
                            action: _ctx.register,
                            disabled: _ctx.registerData.buttonDisabled
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_translate, null, {
                                default: _withCtx(() => _cache[87] || (_cache[87] = [
                                  _createTextVNode("Register")
                                ])),
                                _: 1
                              })
                            ]),
                            _: 1
                          }, 8, ["errors", "action", "disabled"])
                        ], 32)
                      ]),
                      _: 1
                    }, 8, ["fill-width", "large-footer", "large-header", "max-height"]))
                  : (_ctx.action === 'resend-authentication')
                    ? (_openBlock(), _createBlock(_component_bp_card, {
                        key: 5,
                        "fill-height": "",
                        "fill-width": !_ctx.isDesktop,
                        "large-footer": _ctx.isDesktop,
                        "large-header": _ctx.isDesktop,
                        "max-height": _ctx.maxHeight,
                        class: "bp-login-view__card",
                        opaque: ""
                      }, {
                        header: _withCtx(() => [
                          _createElementVNode("h1", _hoisted_19, [
                            _createElementVNode("img", {
                              class: "bp-login-view__cordaware-logo",
                              src: `/static/logo/cordaware_${_ctx.darkMode ? 'dark' : 'light'}.png`
                            }, null, 8, _hoisted_20),
                            _createVNode(_component_translate, { class: "bp-login-view__header-prefix" }, {
                              default: _withCtx(() => _cache[89] || (_cache[89] = [
                                _createTextVNode("Welcome to the")
                              ])),
                              _: 1
                            }),
                            _createVNode(_component_translate, { class: "bp-login-view__header-suffix" }, {
                              default: _withCtx(() => _cache[90] || (_cache[90] = [
                                _createTextVNode("Customer portal")
                              ])),
                              _: 1
                            })
                          ])
                        ]),
                        footer: _withCtx(() => [
                          _createVNode(_component_bp_button, {
                            class: "flex-1",
                            icon: "arrow-left",
                            large: "",
                            to: { name: 'login' }
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_translate, null, {
                                default: _withCtx(() => _cache[95] || (_cache[95] = [
                                  _createTextVNode("Back to login")
                                ])),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        default: _withCtx(() => [
                          _createElementVNode("form", {
                            class: "bp-login-view__card-content-wrapper",
                            onSubmit: _cache[47] || (_cache[47] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.resendAuthentication && _ctx.resendAuthentication(...args)), ["prevent"]))
                          }, [
                            _createVNode(_component_translate, { tag: "h3" }, {
                              default: _withCtx(() => _cache[91] || (_cache[91] = [
                                _createTextVNode("Resend authentication mail")
                              ])),
                              _: 1
                            }),
                            _createVNode(_component_bp_input, {
                              label: _ctx.$gettext('Customer number'),
                              placeholder: _ctx.$gettext('e.g.') + ' ' + _ctx.randomCustomerNumber,
                              onChangeValid: _cache[43] || (_cache[43] = ($event: any) => (_ctx.setResendAuthenticationError('customerNumber', _ctx.$gettext('Customer number'), $event))),
                              "label-position": "top",
                              desiredlength: 8,
                              required: "",
                              type: "text",
                              modelValue: _ctx.resendAuthenticationData.customerNumber,
                              "onUpdate:modelValue": _cache[44] || (_cache[44] = ($event: any) => ((_ctx.resendAuthenticationData.customerNumber) = $event))
                            }, {
                              text: _withCtx(() => [
                                _createVNode(_component_translate, { tag: "p" }, {
                                  default: _withCtx(() => _cache[92] || (_cache[92] = [
                                    _createTextVNode("You can find your customer number in your order confirmation.")
                                  ])),
                                  _: 1
                                }),
                                _createVNode(_component_translate, { tag: "p" }, {
                                  default: _withCtx(() => _cache[93] || (_cache[93] = [
                                    _createTextVNode("If you are already registered you can also find it in the home view after you successfully logged into your account.")
                                  ])),
                                  _: 1
                                })
                              ]),
                              _: 1
                            }, 8, ["label", "placeholder", "modelValue"]),
                            _createVNode(_component_bp_input, {
                              label: _ctx.$gettext('Email address'),
                              placeholder: _ctx.$gettext('e.g.') + ' ' + _ctx.$gettext('forename.lastname@company.com'),
                              onChangeValid: _cache[45] || (_cache[45] = ($event: any) => (_ctx.setResendAuthenticationError('email', _ctx.$gettext('Email address'), $event))),
                              "label-position": "top",
                              maxlength: -1,
                              required: "",
                              type: "email",
                              modelValue: _ctx.resendAuthenticationData.email,
                              "onUpdate:modelValue": _cache[46] || (_cache[46] = ($event: any) => ((_ctx.resendAuthenticationData.email) = $event))
                            }, null, 8, ["label", "placeholder", "modelValue"]),
                            _createVNode(_component_bp_form_button, {
                              large: "",
                              color: "green",
                              icon: ['far', 'envelope'],
                              errors: _ctx.resendAuthenticationErrors,
                              action: _ctx.resendAuthentication
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_translate, null, {
                                  default: _withCtx(() => _cache[94] || (_cache[94] = [
                                    _createTextVNode("Resend authentication mail")
                                  ])),
                                  _: 1
                                })
                              ]),
                              _: 1
                            }, 8, ["errors", "action"])
                          ], 32)
                        ]),
                        _: 1
                      }, 8, ["fill-width", "large-footer", "large-header", "max-height"]))
                    : _createCommentVNode("", true),
        _createVNode(_component_bp_window, {
          class: "bp-login-view__window",
          modelValue: _ctx.twoFAData.visible,
          "onUpdate:modelValue": _cache[50] || (_cache[50] = ($event: any) => ((_ctx.twoFAData.visible) = $event))
        }, {
          header: _withCtx(() => [
            _createVNode(_component_translate, { tag: "h3" }, {
              default: _withCtx(() => _cache[96] || (_cache[96] = [
                _createTextVNode("Two-factor authentication")
              ])),
              _: 1
            })
          ]),
          footer: _withCtx(() => [
            _createVNode(_component_bp_button, {
              color: "green",
              icon: "check",
              onClick: _ctx.twoFA
            }, {
              default: _withCtx(() => [
                _createVNode(_component_translate, null, {
                  default: _withCtx(() => _cache[98] || (_cache[98] = [
                    _createTextVNode("Confirm")
                  ])),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["onClick"])
          ]),
          default: _withCtx(() => [
            _createElementVNode("form", {
              onSubmit: _cache[49] || (_cache[49] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.twoFA && _ctx.twoFA(...args)), ["prevent"]))
            }, [
              _createElementVNode("div", _hoisted_21, [
                _createVNode(_component_translate, null, {
                  default: _withCtx(() => _cache[97] || (_cache[97] = [
                    _createTextVNode("This code should be displayed in your application or on your device.")
                  ])),
                  _: 1
                }),
                _createVNode(_component_bp_label, {
                  label: _ctx.$gettext('Please enter your OTP Code'),
                  "label-position": "top"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_bp_input, {
                      chunks: 6,
                      ref: _ctx.twoFARef,
                      type: "text",
                      modelValue: _ctx.twoFAData.otp,
                      "onUpdate:modelValue": _cache[48] || (_cache[48] = ($event: any) => ((_ctx.twoFAData.otp) = $event))
                    }, null, 8, ["modelValue"])
                  ]),
                  _: 1
                }, 8, ["label"])
              ])
            ], 32)
          ]),
          _: 1
        }, 8, ["modelValue"])
      ], 512)
    ])
  ], 4))
}