import { cssColor, Shade } from './color';
import getCssVariable from './css';

/**
 * Generates an svg as an inline CSS `background-image` based on the current theme color and dark mode.
 * @returns A string to use as a CSS `background-image`: `url('data:image/svg+xml;utf8,<svg>...</svg>')`
 */
export function backgroundImage(): string {
  const darkMode = document.documentElement.getAttribute('data-dark-mode') === 'true';

  function color(shade: Shade) {
    return getCssVariable(cssColor(darkMode ? 'cool-gray' : 'light-blue', shade) || '');
  }

  const image = `<svg xmlns="http://www.w3.org/2000/svg" width="1920" height="1080" viewBox="0 0 1920 1080">
    <defs>
      <clipPath id="clip-path">
        <rect x="0" y="0" width="1920" height="1080" />
      </clipPath>
      <linearGradient id="gradient_1" x1="0" y1="0" x2="1920" y2="0" gradientUnits="userSpaceOnUse">
        <stop offset="0" stop-color="${color(darkMode ? 600 : 400)}" />
        <stop offset="1" stop-color="${color(darkMode ? 800 : 600)}" />
      </linearGradient>
      <linearGradient id="gradient_2" x1="-640" y1="0" x2="1280" y2="0" gradientUnits="userSpaceOnUse">
        <stop offset="0" stop-color="${color(darkMode ? 900 : 700)}" />
        <stop offset="0.25" stop-color="${color(darkMode ? 800 : 600)}" />
        <stop offset="0.5" stop-color="${color(darkMode ? 700 : 500)}" />
        <stop offset="0.75" stop-color="${color(darkMode ? 600 : 400)}" />
        <stop offset="1" stop-color="${color(darkMode ? 500 : 300)}" />
      </linearGradient>
      <linearGradient id="gradient_3" x1="640" y1="0" x2="2560" y2="0" gradientUnits="userSpaceOnUse">
        <stop offset="0" stop-color="${color(darkMode ? 500 : 300)}" stop-opacity="0.5" />
        <stop offset="1" stop-color="${color(darkMode ? 200 : 50)}" stop-opacity="0.5" />
      </linearGradient>
    </defs>
    <rect x="0" y="0" width="1920" height="1080" fill="url(#gradient_1)" />
    <g clip-path="url(#clip-path)">
      <path fill="url(#gradient_2)" d="M-882.02,444.5c0,245.767,285.672,354.559,850.778,445,24.994,4,77.057-4.874,100.974-10,69.982-15,147.081-35.284,216.943-60,145.308-51.408,291.812-116.468,427.889-197,295.444-174.847,544.858-372.029,544.858-512C1259.422-135.267,722.505-24,150.211-24S-882.02,198.733-882.02,444.5Z" />
      <path fill="url(#gradient_3)" d="M707.918,555.962C738.548,491.121,823,420.038,960.5,397.5c62.924-10.314,138.668-14.238,213.944-10,106.326,5.986,216.234,26.12,326.915,49,121.013,25.015,397.187,110.124,418.891,124,295.612,189,236.02,472.289,155.818,642.074-86.09,182.251-466.834,330.483-844.639,151.926-128.354-60.662-238.1-153.429-327.914-253-75.078-83.239-137.377-170.33-173.955-256C681.1,732.006,675.527,624.535,707.918,555.962Z" />
    </g>
  </svg>`;

  return `url('data:image/svg+xml;utf8,${image
    .replaceAll(/\n\s*/gm, '')
    .replaceAll('#', '%23')
  }')`;
}
