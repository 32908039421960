
import { $gettext } from 'vue-gettext';
import { AnonymousUser } from '@/utils/user';
import { backgroundImage } from '@/utils/background-image';
import { Breakpoint, useMedia } from '@/utils/responsive-breakpoints';
import { computed, CSSProperties, defineComponent, nextTick, PropType, ref, VNodeRef, watch } from 'vue';
import { cordawareIcon } from '@/components/icon/BpIcon';
import { currentLanguage, currentLanguageISOString, getTranslated } from '@/translation';
import { encodeURL } from '@/utils/string';
import { json } from '@sahnee/ajax';
import { localizeDate, localizeTime } from '@/utils/date';
import { Navigation, NavigationEntry } from '@/components/navigation/BpNavigation';
import { Preview, useVideoStore, Webinar } from '@/stores/video';
import { useConfigStore } from '@/stores/config';
import { useFormErrors } from '@/utils/form-errors';
import { useLanguageStore } from '@/stores/language';
import { useRouter } from 'vue-router';
import { useSalutationStore } from '@/stores/salutation';
import BpHeader from '@/components/BpHeader.vue';
import BpInputVue from '@/components/input/BpInput.vue';
import BpToast from '@/components/toast/BpToasts';
import useCompactMode from '@/compositions/use-compact-mode';
import useDarkMode from '@/compositions/use-dark-mode';
import VueHcaptcha from '@hcaptcha/vue3-hcaptcha';
import clone from '@sahnee/clone';

export default defineComponent({
  name: 'bp-login-view',
  props: {
    action: String as PropType<'webinar' | 'forgot-password' | 'reset-password' | 'register' | 'resend-authentication' | 'changed-email' | 'registered' | 'changed-password'>,
    email: String,
    token: String,
    success: Boolean,
  },
  components: {
    BpCaptcha: VueHcaptcha
  },
  // TODO: Split logic into separate components, e.g. forgot-password, reset-password, register, two-factor authentication, webinar, etc.
  setup(props) {
    ///-------------------------------------------------------------------
    /// ROUTER
    ///-------------------------------------------------------------------

    const router = useRouter();

    ///-------------------------------------------------------------------
    /// COOKIE CONSENT
    ///-------------------------------------------------------------------

    const cookieConsent = ref(document.cookie.split('; ').find(cookie => cookie.startsWith('best_portal_cookie_consent'))?.split('=').pop() === 'true');

    function openCookieBanner() {
      document.documentElement.dispatchEvent(new CustomEvent('cookie-banner'))
    }

    ///-------------------------------------------------------------------
    /// CAPTCHA
    ///-------------------------------------------------------------------
    
    const captcha = ref<{ token: string, error: string, sitekey: string, loading: boolean }>({ token: '', error: '', sitekey: process.env.VUE_APP_HCAPTCHA_SITE_KEY || '', loading: true })
    
    function captchaInvalid() {
      captcha.value.error = $gettext('Is required.');
      setCaptchaError(captcha.value.error);
    }

    function captchaRendered() {
      captcha.value.loading = false;
      setCaptchaError($gettext('Is required.'));
    }

    function captchaVerify(token: string) {
      captcha.value.token = token;
      captcha.value.error = '';
      setCaptchaError(captcha.value.error);
    }

    function setCaptchaError(message: string) {
      setRegisterError('captcha', $gettext('Captcha'), message);
    }

    // TODO: Update language in script based on current language
    // watch(() => currentLanguage.value, () => {
    //   const captchaScript = document.getElementById('hcaptcha-api-script-id');
    //   captchaScript?.remove();
    // })

    ///-------------------------------------------------------------------
    /// LANGUAGE
    ///-------------------------------------------------------------------

    const languageStore = useLanguageStore();
    const language = computed({
      get: () => currentLanguage.value === 'de' ? 'Deutsch' : 'Englisch',
      set: (lang: string) => {
        currentLanguage.value = lang === 'Deutsch' ? 'de' : 'en_US';
        return lang;
      },
    })

    ///-------------------------------------------------------------------
    /// RESPONSIVE
    ///-------------------------------------------------------------------

    const isDesktop = useMedia(Breakpoint.MIN_MD);
    const isTablet = useMedia(Breakpoint.MIN_SM);

    ///-------------------------------------------------------------------
    /// LAYOUT
    ///-------------------------------------------------------------------

    const maxHeight = ref('calc(100 * var(--vh) - var(--portal-header-height) - var(--padding))');

    ///-------------------------------------------------------------------
    /// HEADER
    ///-------------------------------------------------------------------

    function isActive(entry: NavigationEntry) {
      if (!router.currentRoute.value.name) {
        return false;
      }

      function *elements(el: NavigationEntry): Generator<NavigationEntry> {
        yield el;
        if (el.children) {
          for (const child of el.children) {
            yield *elements(child);
          }
        }
      }

      const routes = [];
      for (const element of elements(entry)) {
        routes.push((element.to && typeof element.to !== 'string' && 'query' in element.to) ? JSON.stringify(element.to.query) : '{}');
      }
      return routes.includes(JSON.stringify(router.currentRoute.value.query));
    }

    ///-------------------------------------------------------------------
    /// CUSTOMIZE APPEARANCE
    ///-------------------------------------------------------------------

    const { current: darkMode, toggle: toggleDarkMode } = useDarkMode();
    const { current: compactMode, toggle: toggleCompactMode} = useCompactMode();

    const customizeAppearance = ref<Navigation>([]);
    watch(() => [compactMode?.value, currentLanguage.value], () => {
      customizeAppearance.value = [
        {
          _id: 'settings',
          name: $gettext('Settings'),
          icon: 'gear',
          children: [
            {
              _id: 'customize_appearance',
              name: $gettext('Customize appearance'),
              icon: 'circle-half-stroke',
              children: [
                {
                  _id: 'dark_mode',
                  name: $gettext('Dark mode'),
                  icon: 'moon',
                  click: toggleDarkMode,
                },
                {
                  _id: 'compact_mode',
                  name: $gettext('Compact mode'),
                  icon: 'arrows-to-dot',
                  click: toggleCompactMode,
                },
              ]
            },
            {
              _id: 'language',
              name: `${$gettext('Language')}: <strong>${currentLanguage.value === 'de' ? $gettext('German') : $gettext('English')}</strong>`,
              icon: 'language',
              children: languageStore.languages.map(lang => ({
                ...lang,
                name: languageStore.getNameOf(lang._id, 'md'),
                click: () => currentLanguage.value = lang._id === 'Deutsch' ? 'de' : 'en_US',
              }))
            },
            {
              _id: 'cookie_consent',
              name: $gettext('Update cookie consent'),
              icon: ['far', 'cookie-bite'],
              click: openCookieBanner,
            },
          ]

        },
      ] as Navigation;
    }, { immediate: true })

    const loginNav = computed(() => {
      return [
        {
          _id: 'customer-portal',
          name: $gettext('Customer portal'),
          icon: cordawareIcon(),
          to: { name: 'login' }
        },
        {
          _id: 'webinar',
          name: $gettext('Webinar'),
          icon: 'video',
          to: { name: 'login', query: { action: 'webinar'}}
        }
      ] as Navigation;
    });

    ///-------------------------------------------------------------------
    /// PLACEHOLDER
    ///-------------------------------------------------------------------

    const randomCustomerNumber = ref(Math.random()
      .toString(36)
      .substring(2, 10)
      .split('')
      .map(char => Math.round(Math.random()) ? char.toUpperCase() : char.toLowerCase())
      .join(''));

    ///-------------------------------------------------------------------
    /// FORM ERROR HANDLING
    ///-------------------------------------------------------------------

    const { errors: forgotPasswordErrors, setError: setForgotPasswordError } = useFormErrors();
    const { errors: registerErrors, setError: setRegisterError } = useFormErrors();
    const { errors: resetPasswordErrors, setError: setResetPasswordError } = useFormErrors();
    const { errors: resendAuthenticationErrors, setError: setResendAuthenticationError } = useFormErrors();
    const { errors: webinarErrors, setError: setWebinarError } = useFormErrors<Webinar>();

    ///-------------------------------------------------------------------
    /// LOGIN
    ///-------------------------------------------------------------------

    const loginInputType = ref<'text' | 'email'>('email');

    const loginInputEl = ref<typeof BpInputVue>();

    const loginDefaultData = {
      email: props.email || '',
      password: '',
    };
    const loginData = ref<{
      email: string,
      password: string
    }>(clone(loginDefaultData));

    watch(() => loginData.value.email, () => {
      if (loginData.value.email === 'admin') {
        loginInputType.value = 'text'
        nextTick(() => {
          const pos = loginData.value.email.length;
          loginInputEl.value?.$el.querySelector('input').setSelectionRange(pos, pos);
        });
      } else if (loginInputType.value === 'text') {
        loginInputType.value = 'email'
      }
    })

    async function login() {
      const response = await json<{
        status: 'logged_in' | 'two_fa' | 'forbidden' | 'not_found' | 'unauthenticated',
        user: {
          roles: string[]
        }
      }>('/api/login', {
        method: 'POST',
        json: {
          email: loginData.value.email,
          password: loginData.value.password,
        },
      });

      let errorMessage = '';
      switch (response.status) {
        case 'logged_in': {
          if (response.user.roles.includes('admin')) {
            router.replace({ name: 'admin.dashboard' });
          } else {
            router.replace({ name: 'home' });
          }
          loginData.value = clone(loginDefaultData);
          return;
        }
        case 'two_fa': {
          twoFAData.value.visible = true;
          nextTick(() => twoFAData.value.inputEl?.$el.querySelector('input')?.focus());
          return;
        }
        case 'forbidden': {
          errorMessage = $gettext('Password is not correct!');
          break;
        }
        case 'not_found': {
          errorMessage = $gettext('Email or password is not correct!');
          break;
        }
        case 'unauthenticated': {
          errorMessage = $gettext('Not authenticated!') + `<br><a href="/#/login?action=resend-authentication&email=${loginData.value.email}">` + $gettext('Resend authentication') + '</a>';
          break;
        }
      }
      BpToast.show({
        color: 'red',
        title: $gettext('Login failed'),
        content: errorMessage,
        icon: 'triangle-exclamation',
      });
    }

    // TWO FACTOR AUTHENTICATION
    const twoFADefaultData = {
      inputEl: null,
      otp: '',
      visible: false,
    };
    const twoFAData = ref<{
      inputEl: typeof BpInputVue | null,
      otp: string,
      visible: boolean
    }>(clone(twoFADefaultData))
    const twoFARef = ((el: typeof BpInputVue) => twoFAData.value.inputEl = el) as unknown as VNodeRef;

    async function twoFA() {
      const response = await json<{
        status: 'logged_in' | 'forbidden',
        user: {
          roles: string[]
        }
      }>('/api/authenticator', {
        method: 'POST',
        json: {
          action: 'validate',
          email: loginData.value.email,
          token: twoFAData.value.otp,
        },
      });

      let errorMessage = '';
      switch (response.status) {
        case 'logged_in': {
          if (response.user.roles.includes('admin')) {
            router.replace({ name: 'admin.dashboard' });
          } else {
            router.replace({ name: 'home' });
          }
          twoFAData.value = clone(twoFADefaultData);
          return;
        }
        case 'forbidden': {
          errorMessage = $gettext('One-Time-Password is not correct!');
          break;
        }
      }
      BpToast.show({
        color: 'red',
        title: $gettext('OTP failed'),
        content: errorMessage,
        icon: 'triangle-exclamation',
      });
    }

    ///-------------------------------------------------------------------
    /// WEBINAR
    ///-------------------------------------------------------------------

    const webinarDefaultData = {
      href: '',
      preview: null,
      previewActive: false,
      previewCheckInterval: NaN,
      token: props.action === 'webinar' && props.token ? props.token : '',
      username: '',
    }
    const webinarData = ref<{
      href: string,
      preview: Preview<Webinar> | null,
      previewActive: boolean,
      previewCheckInterval: number,
      token: string,
      username: string
    }>(clone(webinarDefaultData));

    const configStore = useConfigStore();
    const videoStore = useVideoStore();

    nextTick(async () => {
      await loadNextWebinar();
      if (webinarData.value.preview && webinarData.value.preview.webinar.active === false) {
        webinarData.value.previewCheckInterval = window.setInterval(await checkNextWebinar, 10000);
      }
    });

    watch(() => webinarData.value.previewActive, loadNextWebinar);

    async function loadNextWebinar() {
      webinarData.value.preview = await videoStore.loadNextWebinar() ?? null;
    }

    async function checkNextWebinar() {
      webinarData.value.previewActive = await videoStore.loadNextWebinarActiveStatus();
      if (webinarData.value.previewActive) {
        window.clearInterval(webinarData.value.previewCheckInterval);
        webinarData.value.previewCheckInterval = NaN;
      }
    }

    watch(() => [configStore.isLoading(), currentLanguage.value, webinarData.value.preview], () => {
      if (!configStore.loaded || configStore.isLoading()) { return }
      const emailSettings = configStore.getEmailSettings('webinar.registration');
      const recipients = emailSettings.recipients;
      const subject = encodeURL(getTranslated(emailSettings.subject));
      const body = (encodeURL(getTranslated(emailSettings.body)
        .replaceAll('%{title}', getTranslated(webinarData.value.preview?.name))
        .replaceAll('%{date}', localizeDate(webinarData.value.preview?.webinar?.date || ''))
        .replaceAll('%{time}', localizeTime(webinarData.value.preview?.webinar?.date || ''))
      ));
      webinarData.value.href = `mailto:${recipients}?subject=${subject}&body=${body}`;
    })

    const abortController = ref(new AbortController())
    const usernameValid = ref(true)

    async function checkUsername(username: string) {
      try {
        abortController.value.abort();
        abortController.value = new AbortController();
        const response = await json<{
          status: boolean
        }>('/api/webinar-check-username', {
          method: 'POST',
          json: {
            token: webinarData.value.token,
            username,
          },
          signal: abortController.value.signal
        });
        usernameValid.value = response.status;
      } catch (error: unknown) {
        return;
        // console.error('Error in request:', (error as Error).message);
      }
    }

    const usernameError = computed(() => {
      if (webinarData.value.username && !webinarData.value.token) {
        return $gettext('No webinar token provided');
      }
      if (!usernameValid.value) {
        return $gettext('Username or webinar token invalid');
      }
      return undefined;
    })

    async function participate() {
      const response = await json<{ status: 'logged_in' | 'forbidden', user: AnonymousUser }>('/api/webinar-login', {
        method: 'POST',
        json: {
          token: webinarData.value.token,
          username: webinarData.value.username,
        },
      });

      let errorMessage = '';
      switch (response.status) {
        case 'logged_in': {
          if (response.user.roles.includes('anonymous')) {
            router.replace({ name: 'video.play', params: { slug: webinarData.value.preview?.slug }});
            webinarData.value = clone(webinarDefaultData);
          }
          localStorage.setItem('bp:webinar_' + webinarData.value.preview?._id + '_user_' + response.user._id, JSON.stringify({ _id: response.user._id, username: response.user.username }));
          return;
        }
        case 'forbidden': {
          errorMessage = $gettext('Token is not correct!');
          break;
        }
      }
      BpToast.show({
        color: 'red',
        title: $gettext('Webinar login failed'),
        content: errorMessage,
        icon: 'triangle-exclamation',
      });
    }

    ///-------------------------------------------------------------------
    /// FORGOT PASSWORD
    ///-------------------------------------------------------------------

    const forgotPasswordDefaultData = {
      customerNumber: '',
      email: props.email || '',
    };
    const forgotPasswordData = ref<{
      customerNumber: string,
      email: string
    }>(clone(forgotPasswordDefaultData))

    async function forgotPassword() {
      const passwordResult = await json<{ success: boolean; error?: string; }>('/api/password', {
        method: 'POST',
        json: {
          action: "forgot-password",
          customerNumber: forgotPasswordData.value.customerNumber,
          email: forgotPasswordData.value.email,
        },
      });

      if (passwordResult.success) {
        BpToast.show({
          color: 'green',
          title: $gettext('Password reset instructions email success'),
          content: $gettext('Password reset instructions email was successfully sent.'),
          icon: 'circle-check',
        });
        loginData.value.email = forgotPasswordData.value.email;
        forgotPasswordData.value = clone(forgotPasswordDefaultData);
        router.replace({ name: 'login', query: { email: forgotPasswordData.value.email.toLowerCase() } });
      } else {
        BpToast.show({
          color: 'red',
          title: $gettext('Password reset instructions email failed'),
          content: $gettext('Password reset instructions email failed to send: %{error}', { error: passwordResult.error || ''}),
          icon: 'triangle-exclamation',
        });
      }
    }

    ///-------------------------------------------------------------------
    /// RESET PASSWORD
    ///-------------------------------------------------------------------

    const resetPasswordDefaultData = {
      confirmation: { value: '', dirty: false, error: '' },
      email: props.email || '',
      password: { value: '', dirty: false, error: '' },
    };
    const resetPasswordData = ref<{
      confirmation: { value: string, dirty: boolean, error: string },
      email: string,
      password: { value: string, dirty: boolean, error: string }
    }>(clone(resetPasswordDefaultData));

    async function resetPassword() {
      const response = await json<{ success: boolean, error?: string }>('/api/password', {
        method: 'POST',
        json: {
          action: "reset-password",
          email: resetPasswordData.value.email,
          password: resetPasswordData.value.password.value,
        },
      });

      if (response.success) {
        BpToast.show({
          color: 'green',
          title: $gettext('Password reset success'),
          content: $gettext('Password reset success'),
          icon: 'circle-check',
        });
        loginData.value.email = resetPasswordData.value.email;
        resetPasswordData.value = clone(resetPasswordDefaultData);
        router.replace({ name: 'login', query: { email: resetPasswordData.value.email.toLowerCase() } });
      } else {
        BpToast.show({
          color: 'red',
          title: $gettext('Password reset failed'),
          content: $gettext('Password reset failed: %{error}', { error: response.error || 'unknown error' }),
          icon: 'triangle-exclamation',
        });
      }
    }

    ///-------------------------------------------------------------------
    /// REGISTER
    ///-------------------------------------------------------------------

    const registerDefaultData = {
      buttonDisabled: false,
      confirmation: { value: '', dirty: false, error: '' },
      customerNumber: '',
      email: props.email || '',
      forename: '',
      password: { value: '', dirty: false, error: '' },
      privacyPolicy: false,
      salutation: '',
      surname: '',
    }
    const registerData = ref<{
      buttonDisabled: boolean,
      confirmation: { value: string, dirty: boolean, error: string },
      customerNumber: string,
      email: string,
      forename: string,
      password: { value: string, dirty: boolean, error: string },
      privacyPolicy: boolean,
      salutation: string,
      surname: string,
    }>(clone(registerDefaultData))

    const salutationStore = useSalutationStore();

    async function register() {
      registerData.value.buttonDisabled = true;
      const response = await json<{ success: boolean, error?: string }>('/api/register', {
        method: 'POST',
        json: {
          action: 'register',
          token: captcha.value.token,
          customerNumber: registerData.value.customerNumber,
          email: registerData.value.email,
          forename: registerData.value.forename,
          password: registerData.value.password.value,
          salutation: registerData.value.salutation,
          surname: registerData.value.surname,
        },
      });
      if (response.success) {
        BpToast.show({
          color: 'green',
          title: $gettext('Register success'),
          content: $gettext('Register success. Email to authenticate was sent.'),
          icon: 'circle-check',
        });
        loginData.value.email = registerData.value.email;
        registerData.value = clone(registerDefaultData);
        router.replace({ name: 'login', query: { email: registerData.value.email.toLowerCase() } })
      } else {
        BpToast.show({
          color: 'red',
          title: $gettext('Register failure'),
          content: $gettext('Register failed: %{error}', { error: response.error || 'Unknown error' }),
          icon: 'triangle-exclamation',
        });
      }
      registerData.value.buttonDisabled = false;
    }

    ///-------------------------------------------------------------------
    /// RESEND AUTHENTICATION
    ///-------------------------------------------------------------------

    const resendAuthenticationDefaultData = {
      customerNumber: '',
      email: props.email || '',
    }
    const resendAuthenticationData = ref<{
      customerNumber: string,
      email: string
    }>(clone(resendAuthenticationDefaultData));

    watch(() => props.email, () => {
      resendAuthenticationData.value.email = props.email || '';
    })

    async function resendAuthentication() {
      const authResult = await json<{ success: boolean; error: string; }>('/api/register', {
        method: 'POST',
        json: {
          action: "resend-authentication",
          customerNumber: resendAuthenticationData.value.customerNumber,
          email: resendAuthenticationData.value.email,
        },
      });

      if (authResult.success) {
        BpToast.show({
          color: 'green',
          title: $gettext('Authenticate success'),
          content: $gettext('Authenticate success. Email was sent.'),
          icon: 'circle-check',
        });
        loginData.value.email = resendAuthenticationData.value.email;
        resendAuthenticationData.value = clone(resendAuthenticationDefaultData);
        router.replace({ name: 'login', query: { email: resendAuthenticationData.value.email.toLowerCase() } })
      } else {
        BpToast.show({
          color: 'red',
          title: $gettext('Authenticate failure'),
          content: authResult.error,
          icon: 'triangle-exclamation',
        });
      }
    }

    ///-------------------------------------------------------------------
    /// STYLE
    ///-------------------------------------------------------------------

    const headerEl = ref<typeof BpHeader>();
    const mainEl = ref<HTMLElement>();

    const style = ref<CSSProperties>({});
    watch(() => [headerEl.value, mainEl.value, darkMode?.value], () => {
      // BACKGROUND IMAGE
      const image = { '--portal-image': backgroundImage() };

      // HEADING COLOR
      const headingColor = { '--portal-heading-color': (darkMode && darkMode.value) ? 'var(--theme-text)' : 'var(--color-cw-blue)' };

      // HEADER HEIGHT CALCULATION
      nextTick(() => {
        const header = headerEl.value && headerEl.value.$el as HTMLElement | undefined;
        const main = mainEl.value;
        if (!header || !main) {
          style.value = {
            ...image,
            ...headingColor,
          };
          return;
        }
        const boundingRect = header.getBoundingClientRect();
        const marginTop = parseFloat(window.getComputedStyle(main).getPropertyValue('margin-top'))
        style.value = {
          ...image,
          ...headingColor,
          '--portal-header-height': (Math.max(boundingRect.height, 3.15 * 16) + boundingRect.y + marginTop) + 'px',
        }
      })
    }, { immediate: true })

    ///-------------------------------------------------------------------
    /// TOASTS
    ///-------------------------------------------------------------------

    if (props.action === 'changed-email') {
      if (props.success === true) {
        BpToast.show({
          color: 'green',
          title: $gettext('Success'),
          content: $gettext('Email changed successfully.'),
          icon: 'circle-check',
        });
        router.replace({ name: 'login', query: { email: props.email ? props.email.toLowerCase() : undefined } })
      } else {
        BpToast.show({
          color: 'red',
          title: $gettext('Failure'),
          content: $gettext('Email could not be changed.'),
          icon: 'triangle-exclamation',
        });
      }
    }

    if (props.action === 'changed-password') {
      if (props.success === true) {
        BpToast.show({
          color: 'green',
          title: $gettext('Success'),
          content: $gettext('Password changed successfully.'),
          icon: 'circle-check',
        });
        router.replace({ name: 'login', query: { email: props.email ? props.email.toLowerCase() : undefined } })
      } else {
        BpToast.show({
          color: 'red',
          title: $gettext('Failure'),
          content: $gettext('Password could not be changed.'),
          icon: 'triangle-exclamation',
        });
      }
    }

    if (props.action === 'registered') {
      if (props.success === true) {
        BpToast.show({
          color: 'green',
          title: $gettext('Authentication successful'),
          content: $gettext('Authentication successful'),
          icon: 'circle-check',
        });
        router.replace({ name: 'login', query: { email: props.email ? props.email.toLowerCase() : undefined } })
      } else {
        BpToast.show({
          color: 'red',
          duration: -1,
          title: $gettext('Authentication failure'),
          content: $gettext('Authentication failure <a href="/#/login?action=resend-authentication">Resend</a> '),
          icon: 'triangle-exclamation',
        });
      }
    }

    ///-------------------------------------------------------------------
    /// RETURN
    ///-------------------------------------------------------------------

    return {
      checkUsername,
      compactMode,
      customizeAppearance,
      currentLanguage,
      currentLanguageISOString,
      darkMode,
      getTranslated,
      headerEl,
      isActive,
      isDesktop,
      isTablet,
      language,
      languageStore,
      localizeDate,
      localizeTime,
      loginNav,
      mainEl,
      maxHeight,
      randomCustomerNumber,
      style,
      usernameError,
      usernameValid,

      // LOGIN
      login,
      loginData,
      loginInputType,
      loginInputEl,

      // TWO FACTOR AUTHENTICATION
      twoFA,
      twoFAData,
      twoFARef,

      // FORGOT PASSWORD
      forgotPassword,
      forgotPasswordData,
      forgotPasswordErrors,
      setForgotPasswordError,

      // REGISTER
      register,
      registerData,
      registerErrors,
      setRegisterError,
      salutationStore,

      // RESEND AUTHENTICATION
      resendAuthentication,
      resendAuthenticationData,
      resendAuthenticationErrors,
      setResendAuthenticationError,

      // RESET PASSWORD
      resetPassword,
      resetPasswordData,
      resetPasswordErrors,
      setResetPasswordError,

      // WEBINAR
      participate,
      webinarData,
      webinarErrors,
      setWebinarError,

      // COOKIE CONSENT
      cookieConsent,
      openCookieBanner,

      // CAPTCHA
      captcha,
      captchaVerify,
      captchaInvalid,
      captchaRendered,
    }
  }
});
